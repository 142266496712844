import React from 'react';
import Typography from '@mui/material/Typography';

const PlayResponsible = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <Typography
        variant="body1"
        style={{
          textTransform: 'uppercase',
        }}
      >
       Gambling should be entertaining and undertaken responsibly. You are advised to set limits for your gambling activities, gamble within your means, and recognize that it is not a way to make money. 

If you believe gambling is having a negative impact on your life or someone else's, seek help and support. Contact Gambling Help Online at 1800 858 858 or visit www.gamblinghelponline.org.au.

It's important to be aware of the risks associated with gambling and to make informed decisions. For more information on responsible gambling, visit the Australian Government's website at www.australia.gov.au or seek assistance from local support services.

Remember, you must be 18 years or older to participate in gambling activities, and it is your responsibility to comply with the laws and regulations applicable in your jurisdiction.

Please gamble responsibly.
      </Typography>
    </div>
  );
};

export default PlayResponsible;
