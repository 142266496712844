import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles'; // Import makeStyles for styling
import Grow from '@mui/material/Grow';
import {FcSteam, FcPanorama, FcSportsMode} from "react-icons/fc"

// Define styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 14, 
    borderRadius: '30px',
    minHeight: '200px', 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    opacity: "0.8",
    color: "#ffffff",
    background: 'linear-gradient(360deg, #893D63 25%, #6C1840 100%);', 
  },
}));

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom textAlign="center">Why Choose Us</Typography>
      <Grid container justifyContent="center" spacing={3} marginTop="2rem">
      <Grow in={true}  timeout={1000} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <FcSteam fontSize="4rem" gutterBottom/>
            <Typography variant="h5">
              Vast Game Selection
            </Typography>
          </Paper>
        </Grid>
      </Grow>

      <Grow in={true}  timeout={1200} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <FcPanorama fontSize="4rem" gutterBottom/>
            <Typography variant="h5">
              Immersive Gameplay
            </Typography>
          </Paper>
        </Grid>
        </Grow>

        <Grow in={true}  timeout={1400} appear={true}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
          <FcSportsMode fontSize="4rem" gutterBottom/>
            <Typography variant="h5" gutterBottom>
              Community and Competitions
            </Typography>
          </Paper>
        </Grid>
        </Grow>
      </Grid>
    </Container>
  );
}
