import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import {logo} from "../assets";
import { BiBookmarkHeart } from "react-icons/bi";


function Header() {

  return (
    <div style={{height: "100px"}}>
      <AppBar position="static" style={{ padding: 4, background: "#75c922e5", position: "fixed", zIndex: "99"}}>
        <Toolbar >
          <Box className="logo" sx={{ flexGrow: 1, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <Link to="/">
            <img
                src={logo}
                alt="brand logo"
                style={{ maxWidth: '70px', width: "100%", height: 'auto' }}
              />
            </Link>
            <Link to="/" style={{ textDecoration: 'none', color: 'white'}}>
              <Typography className="logo-title"> <span sx={{fontSize: {xs:"1.3rem", sm: "1.5rem", md: "2rem"}}}>wavehisa</span>  </Typography>
            </Link>
            <Link to="/myList" style={{ textDecoration: 'none', color: 'white', display: "flex", alignItems: "center", gap: ".5rem"}}>
              <BiBookmarkHeart style={{fontSize: "1.8rem"}}/>
                <Typography className="my-list-link" sx={{ 
                  display: {
                    xs: 'none', // hides the element on extra-small screens
                    md: 'block' // displays the element on small screens and above
                  } 
                }}>My list</Typography>
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Header;
