import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { ctaBg } from "../assets";
import { Button } from "@mui/material";
import { BsDot } from "react-icons/bs";

function Copyright() {
  return (
    <Typography variant="body2">
      {"Copyright © "}
      Pokiestrastgames {new Date().getFullYear()}
      {"."} <br />
      The Services are virtual pokies-style entertainment games, and do not
      involve any real money bets or prizes.
    </Typography>
  );
}

function PlayResponsible() {
  return (
    <Typography
      variant="body1"
      style={{
        textTransform: "uppercase",
      }}
    >
      Gambling should be entertaining and undertaken responsibly. You are
      advised to set limits for your gambling activities, gamble within your
      means, and recognize that it is not a way to make money. If you believe
      gambling is having a negative impact on your life or someone else's, seek
      help and support. It's important to be aware of the risks
      associated with gambling and to make informed decisions. For more
      information on responsible gambling, visit the Australian Government's
      website at <Link to="https://www.dss.gov.au/" target="_blank" style={{color: "#ffffff"}}> dss.gov.au </Link> or seek assistance from local support
      services. Remember, you must be 21 years or older to participate in
      gambling activities, and it is your responsibility to comply with the laws
      and regulations applicable in your jurisdiction. Please gamble
      responsibly.
    </Typography>
  );
}

export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: "auto",
          borderTop: "1px solid #3D550C",
        }}
        style={{
          background: "#75c922e5",
        }}
      >
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: "2rem",
          }}
        >
          <div>
            <Button color="inherit" component={Link} to="/about">
              About Us
            </Button>
            <Button color="inherit" component={Link} to="/contacts">
              Contacts
            </Button>
          </div>
          <Typography variant="body1">
            <Link
              to="/terms"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              Terms
            </Link>{" "}
            <BsDot style={{ verticalAlign: "middle" }} />{" "}
            <Link
              to="/privacy"
              style={{ color: "#ffffff", textDecoration: "none" }}
            >
              Privacy Policy
            </Link>
          </Typography>
          {/* <Copyright /> */}
        </Container>
        <Box maxWidth="md" mx="auto" style={{textAlign: "center", borderTop: ".1px solid #ffffff4a", padding: "2rem 0"}}>
          <PlayResponsible />
        </Box>
      </Box>
  );
}
