import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import games from '../data/games.js';
import Typography from '@mui/material/Typography';
import { BiSolidHeart, BiHeart } from "react-icons/bi";
import hero from "../assets/hero.jpg";

function GameList() {
  // Initialize myList state with data from localStorage
  const [myList, setMyList] = useState(() => {
    const savedList = localStorage.getItem('myList');
    return savedList ? JSON.parse(savedList) : [];
  });

  // Update localStorage whenever myList changes
  useEffect(() => {
    localStorage.setItem('myList', JSON.stringify(myList));
  }, [myList]);

  const toggleGameInList = (gameId) => {
    setMyList((prevList) => {
      if (prevList.includes(gameId)) {
        return prevList.filter(id => id !== gameId);
      } else {
        return [...prevList, gameId];
      }
    });
  };

  const isGameInList = (gameId) => {
    return myList.includes(gameId);
  };

  return (
    <Container maxWidth="xl">
      <Typography textAlign="center" variant="h1" fontWeight="900" sx={{
    fontSize: {
      xs: '2.7rem', // slightly larger font size on small devices
      sm: '3rem', // slightly larger font size on small devices
      md: '6rem', // default h1 font size on medium devices and above
    }
  }}>Our selection of games</Typography>
      <Box style={{background: `url(${hero})`, backgroundAttachment: "fixed", maxWidth: "100%", width: "100%", height: "300px", margin: "2rem auto"}}/>
      <Typography textAlign="center" gutterBottom variant="body1" fontStyle="italic"  margin="1rem auto 2rem">Add your favourite games to 'My List' by clicking the Heart icon for lightning-fast access and non-stop fun!</Typography>

      <Grid container spacing={3}>
        {games.map((game) => (
          <Grid item xs={12} sm={4} md={3} key={game.id}>
            <Card
              sx={{
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
                backgroundColor: "primary.main500"
              }}
            >
              <CardActionArea to={`/game/${game.id}`}>
                <CardMedia
                  component="img"
                  alt={game.title}
                  height="180"
                  image={game.image}
                  title={game.title}
                  style={{ fontSize: '14px' }}
                />
                <CardContent textAlign="center">
                  <Typography variant="h6" component="div" gutterBottom>
                    {game.title}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/game/${game.id}`}
                  >
                    Play now
                  </Button>
                  {isGameInList(game.id) ? (
                    <BiSolidHeart
                      style={{fontSize: "1.8rem", float: "right", cursor: "pointer"}}
                      onClick={() => toggleGameInList(game.id)}
                    />
                  ) : (
                    <BiHeart 
                      style={{fontSize: "1.8rem", float: "right", cursor: "pointer"}}
                      onClick={() => toggleGameInList(game.id)}
                    />
                  )}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default GameList;
