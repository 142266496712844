// src/components/CTA.js
import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { ctaBg } from "../assets";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";

function CTA() {
  return (
    <div style={{ background: "#75c922" }}>
      <Container maxWidth="lg">
          {/* Text Block */}
          <Grow in={true} timeout={1200} appear={true}>
            <Box mt={3}>
              <Typography variant="h4" fontStyle="italic" gutterBottom>
              Journey through virtual realms with us!
              </Typography>
             
              <Typography variant="body1" paragraph>
              The entertainment on our site is designed purely for enjoyment. wavehisa.com does not facilitate real-money betting nor does it guarantee monetary rewards. Access to our gaming world is complimentary, welcoming anyone who satisfies our entry requirements.
 </Typography>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/about"
              >
                Explore further
              </Button>
              <Typography variant="h4" paragraph fontWeight="900" textAlign="right">18+</Typography>
              <Typography variant="h6" paragraph fontWeight="900" textAlign="right"> All games
                are intended for a mature audience. By accessing and using this
                website, users declare that they are of legal age to participate
                in the entertainment provided.</Typography>
            
            </Box>
          </Grow>
      </Container>
    </div>
  );
}

export default CTA;
